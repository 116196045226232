@tailwind base;
@tailwind components;
@tailwind utilities;

#root {
  height: 100vh;
  width: 100vw;
}

div::-webkit-scrollbar {
  width: 0.3vw;
}

div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0.5rem;
}
